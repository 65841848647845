import Swup from 'swup';
import SwupJsPlugin from '@swup/js-plugin';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupGtmPlugin from '@swup/gtm-plugin';
import { gsap } from "gsap";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  const tl = gsap.timeline();

  const animationOptions = [
    {
      from: '(.*)',
      to: '(.*)',
      in: function (next) {
        tl.to('.loader__piece', {
          duration: .4,
          width: 0,
          delay: .2,
          ease: "power2.in",
          stagger: .1,
          onComplete: next
        });
        tl.to('.loader', {
          duration: .1,
          display: 'none'
        });
      },
      out: function (next) {
        tl.to('.loader', {
          duration: .1,
          display: 'block'
        });
        tl.to('.loader__piece', {
          duration: .4,
          width: '100%',
          ease: "power2.out",
          stagger: .1,
          onComplete: next
        });
      }
    }
  ];

  function handleDOMContentLoaded() {
    new Swup({
      containers: [
        '.js-content',
        '.js-ajax'
      ],
      plugins: [
        new SwupJsPlugin(animationOptions),
        new SwupHeadPlugin(),
        new SwupScriptsPlugin(),
        new SwupScrollPlugin(),
        new SwupGtmPlugin()
      ]
    });
  }
})();
